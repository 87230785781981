import React, { Component,useState,useEffect } from 'react';
import { 
  Popover,
  Tooltip,
  Modal,  Button
} from 'react-bootstrap';
import BootstrapTable,{ TableHeaderColumn} from 'react-bootstrap-table-next';

import paginationFactory from "react-bootstrap-table2-paginator";

import { submitContactUs } from '../../action/app.action';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getBloodbankList } from "../../action/app.action";

const BloodBankModal = (props)=> {
  const [bloodBankList, setBloodBankList] = useState([]);
  useEffect(() => {
  
  props.getBloodbankList(); 
  }, []);


const handleShow = () => {
}
const handleClose = () => {
  props.setClose(false)
}

const columns = [{
    dataField: 'service_name',
    text: 'Name',
  },
  {
    dataField: 'service_phone',
    text: 'Phone Number'
  },
  {
    dataField: 'service_address',
    text: 'Address'
  },
  {
    dataField: 'service_email',
    text: 'Email-Id'
  }
];

const [open, setOpen] = React.useState(true);
const [loginForm, setLoginForm] = useState({
          name:'',
          email: undefined,
          contactNumber: undefined,
          emailValid: false,
          contactNumberValid: false,
          displayEmailErrorMessage:false,
          displayContactNumberErrorMessage:false,
          disableButton:true,
          address:''
        });
        const [error, setError] = useState({ email: undefined, contactNumber: undefined });

// state update        
        const handleLoginForm = (e) => {
          checkValidity(e.target.name, e.target.value);
          setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
        };
//Focus Function 
        const handleFocusForm=(e)=>{
        //  console.log(e.target.name);
        }        
// validation function        
        const checkValidity = (inputName, inputValue) => {
          switch (inputName) {
            case "email":
              let pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
              loginForm.emailValid = pattern.test(inputValue);
              if(!loginForm.emailValid){
                loginForm.displayEmailErrorMessage=true;
              }
              else{
                loginForm.displayEmailErrorMessage=false;
              }            
              break;
            case "contactNumber":
              loginForm.contactNumberValid = inputValue.length == 10;
              if(!loginForm.contactNumberValid){
                loginForm.displayContactNumberErrorMessage=true;
                
              }
              else{
                loginForm.displayContactNumberErrorMessage=false;
              }     
              break;
            default:
              break;
             
          }
          if(loginForm.emailValid && loginForm.contactNumberValid){
            loginForm.disableButton=false;
          }
          else{
            loginForm.disableButton=true;
          }
        };
// form submit       
        const onSubmitForm = (e) => {
          e.preventDefault();
          if(loginForm.contactNumberValid && loginForm.emailValid){
            let reqForm = {
              name:loginForm.name,
              email:loginForm.email, 
              phone:loginForm.contactNumber,
              comment: "Blood Bank Request"+loginForm.address,
            };
            setOpen(!open);
           props.submitContactUs(reqForm);    
            
          }
              
        };
      

    return (
       <div className="ambulanceDiv">
       
          <Modal size="lg" show={props.isVisible} onHide={handleClose} className="custom-modal-style">
             <Modal.Header closeButton>
               <Modal.Title>Blood Bank List</Modal.Title>
             </Modal.Header>
             <Modal.Body>

             { open && ( <div className="form">
                    <p className="">Please fill the below form-Our Executive will contact you for better Service!!!</p>

                    <form onSubmit = {onSubmitForm} className="login-form">
                      <div className="info-box">
                        <label>Name</label>
                        <input
                          type="text"
                          name="name"
                          className="input-css"

                          inputValue={loginForm.name}
                        />
                      </div>
                      <br/>  <br/>

        
                      <div className="info-box">
                        <label>Email-Id <span className="requiredField">*</span></label>
                        <input
                          type="text"
                          name="email"
                          className="input-css"
                          onBlur={(e) => handleLoginForm(e)}
                          onFocus={(e) =>handleFocusForm(e)}
                          inputValue={loginForm.email}
                        />
                         {loginForm.displayEmailErrorMessage?<label className="errorMessage">Please enter valid Email Id</label>:null}
                      </div>
                      <br/>  <br/>

                      <div className="info-box">
                      <label>Contact Number <span className="requiredField">*</span></label>
                        <input
                          type="number"
                          name="contactNumber"
                          className="input-css"
                          onBlur={(e) =>handleLoginForm(e)}
                          onFocus={(e) =>handleFocusForm(e)}
                          inputValue={loginForm.contactNumber
                          }
                        />
                         {loginForm.displayContactNumberErrorMessage?<label className="errorMessage">Please enter valid Contact Number</label>:null}
                      </div><br></br><br></br>
                      <div className="info-box">
                      <label>Locality/Address</label><br></br>
                        <textarea className="addressTxt" name="address" rows="3" cols="50" inputValue={loginForm.address}> </textarea>
                      </div>
                      <br/>  <br/>
                      <br/>  <br/>
                      <div className="viewBtn">
                      
                      <Button  disabled={loginForm.disableButton} variant="success" type="submit">VIEW BLOOD BANK LIST</Button>
                      </div>
                    </form>
              </div>)
              }
            { !open && ( <BootstrapTable
        keyField="sno"
        data={props.bloodBankList}
        columns={columns}
        pagination={paginationFactory({ sizePerPage: 5 })}
      />)}
             </Modal.Body>
          </Modal>
        </div>
    )
}



const mapStateToProps = (state) => {
  return {
    bloodBankList: state.bloodBankList,
    submitContactUsResponse:state.countactusFormResponse

  };
};

const mapDispatchToState = (dispatch) => {
  return bindActionCreators(
    {
        getBloodbankList: getBloodbankList,
        submitContactUs:submitContactUs

    },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToState
)(BloodBankModal);
