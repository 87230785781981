import Axios from 'axios';
import { PAGE_CONTENT_LIST,AMBULANCE_LIST,BLOODBANK_LIST,PRODUCT_LIST,UPDATEDBUYPRODUCT_ARRAY_LOCALSTORE, UPDATEDRENTALPRODUCT_ARRAY_LOCALSTORE,USERFORMDATA_IN_STORE,NEWSLETTER_RESPONSE,CONTACTUS_RESPONSE,PURCHASEORDER_RESPONSE,SERVICE_CONTENT_RESPONSE } from './actionType';
import { URL } from "../common/API";
import swal from 'sweetalert';

const header =   { headers: { 'Content-Type' : 'application/json; charset=utf-8', 'Accept': 'application/json'} };

export const getAmbulanceList = () => {
  return dispatch => {
      Axios.get(URL.ambulanceList)
      .then(res => {
          dispatch({
            type: AMBULANCE_LIST,
            payload: res.data.output
          });
        })
       
  }
}

export const getBloodbankList = () => {
  return dispatch => {
      Axios.get(URL.bloodBankList)
      .then(res => {
          dispatch({
            type: BLOODBANK_LIST,
            payload: res.data.output
          });
        })
       
  }
}


export const getProductList = () => {
  return dispatch => {
      Axios.get(URL.productListAPI)
      .then(res => {
          dispatch({
            type: PRODUCT_LIST,
            payload: res.data.output
          });
        })
       
  }
}

//Service Module Dynamic Content
export const getServiceContent = () => {
  return dispatch => {
      Axios.get(URL.serviceContentResponseAPI)
      .then(res => {
          dispatch({
            type: SERVICE_CONTENT_RESPONSE,
            payload: res.data.output
          });
        })
       
  }
}

//Dynamic page content data
export const getPageContents = () => {
  return dispatch => {
      Axios.get(URL.pageContentAPI)
      .then(res => {
          dispatch({
            type: PAGE_CONTENT_LIST,
            payload: res.data.output
          });
        })
       
  }
}

//Buy Product array buyProductComponent to FORM Modal Product(Local STORE)
export const updateBuyProductArrayLocalStore = (buyProductArray) => {
    return dispatch => {
          dispatch({
            type: UPDATEDBUYPRODUCT_ARRAY_LOCALSTORE,
            payload: buyProductArray
          });    
  }
}

//Rental Product array buyProductComponent to FORM Modal Product(Local STORE)
export const updateRentProductArrayLocalStore = (rentProductArray) => {
  return dispatch => {
        dispatch({
          type: UPDATEDRENTALPRODUCT_ARRAY_LOCALSTORE,
          payload: rentProductArray
        });    
}
}

//Store Form Values when user fills the Contact Details form(Local STORE)
export const submitFormBuyProductStoreValue = (formData) => {
  formData.isUserFormFilled=true;
  return dispatch => {
        dispatch({
          type: USERFORMDATA_IN_STORE,
          payload: formData  
});
}
}

//News Letter
export const submitNewsLetter = (formData) => {
  return dispatch => {
    Axios.post(URL.submitNewsLetterAPI,formData)
    .then(res => {
        dispatch({
          type: NEWSLETTER_RESPONSE,
          payload: res.data.output
        });
        swal({
          title: "Your submission has been sent",
          text: "You've successfully subscribed to our newsletter. ",
          icon: "success",
          timer: 5000,
          button: false
        }); 
      }).catch(err => {
       
        swal("Oops!", "Something went wrong!", "error"); 
      });       
}
}

//Submit Contact us Form
export const submitContactUs = (formData) => {
  return dispatch => {
    Axios.post(URL.submitContactUsAPI,{...formData})
    .then(res => {
      swal({
        title: "Your submission has been sent",
        text: "Thank you for contacting us! ",
        icon: "success",
        timer: 5000,
        button: false
      }); 
        dispatch({
          type: CONTACTUS_RESPONSE,
          payload: res.data.output
        });
      }) .catch(err => {
       
        swal("Oops!", "Something went wrong!", "error"); 
      });       
}
}


//Submit form and product summary when user Buys a product
export const submitPurchaseOrder = (formData) => {
  return dispatch => {
    Axios.post(URL.submitPurchaseOrderAPI,{...formData})
    .then(res => {
      swal({
        title: "Your Purchase Order has been Submitted Successfully",
        text: "Thank you for your order! ",
        icon: "success",
        timer: 5000,
        button: false
      }); 
        dispatch({
          type: PURCHASEORDER_RESPONSE,
          payload: res.data.output
        });
      }) .catch(err => {
       
        swal("Oops!", "Something went wrong!", "error"); 
      });       
}
}


/*IP Address tracking*/
export const trackVisitorsIPaddress = (formData) => {
  return dispatch => {
    Axios.post(URL.trackVisitorsIP)
    .then(res => {
            console.log("appaction");
           console.log(res.data.IPv4);
           fetch(URL.storeVisitorsIP, {
            method: 'POST',
            body: JSON.stringify({ ip:res.data.IPv4 }),
            headers: { 'Content-Type': 'application/json' },
          })
            .then(res => res.json())
            .then()
      }) .catch(err => {
       
        
      });       
}
}


/*export const getCustomer = () => {
    return dispatch => {
        Axios.get(URL.customerLists)
        .then(res => {
            dispatch({
              type: GET_CUSTOMERS,
              payload: res.data.output[0]
            });
          })
          .catch(err => {
              dispatch({
                type: GET_CUSTOMERS,
                payload: {"error":"Error Connection"}
              });
          });
    }
}*/

/*export const login = (e) => {
  return dispatch => {
      Axios.post(URL.login, e, header )
      .then(res => {
          dispatch({
            type: IS_LOGIN,
            payload: res.data
          });
        })
        .catch(err => {
            dispatch({
              type: IS_LOGIN,
              payload: {"error":"Error Connection"}
            });
        });
  }
}*/


/*export const registerWorker = (e) => {
  return dispatch => {
      Axios.post(URL.registerWorker, e, header )
      .then(res => {
          dispatch({
            type: ADD_WORKER,
            payload: res.data
          });
        })
        .catch(err => {
            dispatch({
              type: ADD_WORKER,
              payload: {"error":"Error Connection"}
            });
        });
  }
}*/


/*export const getproduct = () => {
  return dispatch => {
      Axios.get(URL.getproducts)
      .then(res => {
          dispatch({
            type: GET_PRODUCT,
            payload: res.data.output
          });
        })
        .catch(err => {
            dispatch({
              type: GET_CUSTOMERS,
              payload: {"error":"Error Connection"}
            });
        });
  }
}*/