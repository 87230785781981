import React,{useState,useEffect} from 'react';
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Header from './components/Header/Header';
import Home from './containers/Home/Home'; 
import Footer from './components/Footer/Footer';
import Contact from './components/Contact/Contact';
import About from './components/About/About';
import Services from './components/Service/Services';
import BuyProduct from './components/Buy_Rent/BuyProduct';
import bloodBankIcon from './assets/bloodBank.png';
import AmbulanceModal from './components/Modal/AmbulanceModal';
import './App.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Provider } from 'react-redux';
import { store } from './Store';
import BloodBankModal from './components/Modal/BloodBankModal';
import Badge from 'react-bootstrap/Badge';
import { BadgeMessageService } from './badge_services/badgeMessageService'
import PurchaseItemModal from './components/Modal/PurchaseItemModal';
import  Navbar  from 'react-bootstrap/Navbar';


function App(props) {


  const [messages, setMessages] = useState([]);
  const[badgeCountVar,setBadgeCountValue]=useState(0);
  useEffect(() => {

      // subscribe to home component messages
      const subscription = BadgeMessageService.getMessage().subscribe(message => {
          if (message) {
              console.log("Array Value from BuyProduct Component file");
             console.log(message.Number);
            
             setBadgeCountValue(message.Number);
              // add message to local state if not empty
              //setMessages(messages => [...messages, message]);
          } else {
              // clear messages when empty message received
             // setMessages([]);
          }
      });

      // return unsubscribe method to execute when component unmounts
      return subscription.unsubscribe;
  }, []);

 
  
 
  const [isAmbulanceVisible, SetisAmbulanceVisible] = useState(false);
  const [isBloodBankVisible, SetisBloodBankVisible] = useState(false);
  const [isPurchaseModalVisible, SetisPurchaseModalVisible]=useState(false);
  return (
    <Provider store={store}>
<modal></modal>
    <div className="App">
      <AmbulanceModal isVisible={isAmbulanceVisible} setClose={SetisAmbulanceVisible}></AmbulanceModal>
      <BloodBankModal isVisible={isBloodBankVisible} setClose={SetisBloodBankVisible}></BloodBankModal>
      <PurchaseItemModal isVisible={isPurchaseModalVisible} setClose={SetisPurchaseModalVisible}></PurchaseItemModal>

      <div className="float-sm">
        <div className="fl-fl float-fb" onClick={()=> {SetisAmbulanceVisible(!isAmbulanceVisible)}}>
          <i className="fa fa-ambulance ambulanceIcon" aria-hidden="true"></i>
          <span className="txt">AMBULANCE SERVICE</span>
        </div>
        <div className="fl-fl float-tw" onClick={()=> {SetisBloodBankVisible(!isBloodBankVisible)}}>
        <i className="fa fa-tint bloodBankIcon" aria-hidden="true"></i>
        <span className="txt">BLOOD BANK</span>
        </div>

        <div className="cartIcon float-cart" disabled={badgeCountVar == 0} onClick={()=> {SetisPurchaseModalVisible(!isPurchaseModalVisible)}}>
        <i className="fa fa-shopping-cart cartPadding" aria-hidden="true">
        <Badge variant="danger" className={badgeCountVar > 0 ?'showFormCls':'hiddenFormCls'}>{badgeCountVar}</Badge>
        </i>
        <span className="txt"></span>
        </div>
    </div>

      <Router>
      <Header />
        <div className="mainContent-wrapper">
          <Switch>
         
            <Route exact path="/" component={Home} />
            <Route exact path="/about" component={About} />
            <Route exact path="/Services" component={Services} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/admin" component={Contact} />
            <Route exact path="/shop" component={BuyProduct} />
          </Switch>
        </div>
      </Router>
  <Footer />
    </div>
    </Provider>
  );
}


export default App;
