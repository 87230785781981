export const MenuItems = [
{
    title:'Home',
    url:'/',
    cName: 'nav-links' 
},
{
    title:'About Us',
    url:'/about',
    cName: 'nav-links' 
},
{
    title:'Services',
    url:'/services',
    cName: 'nav-links' 
},
{
    title:'Buy/Rent',
    url:'/shop',
    cName: 'nav-links' 
},
{
    title:'Contact us',
    url:'/contact',
    cName: 'nav-links' 
}
    
]