
import React, { Component } from 'react';
import './Footer.css';
import Banner from '../../assets/side-logo1.png';
import ServiceImg from '../../assets/service.png';
import { Col, Row, Container } from 'react-bootstrap';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { submitNewsLetter } from '../../action/app.action';
class Footer extends Component{
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {}
          }
    
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
    

handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });
}
    
handleSubmit(e) {
    console.log(this.validateForm());
      
    e.preventDefault();
    if (this.validateForm()) {
        console.log(this.state);
        this.props.submitNewsLetter(this.state.fields);
        this.state.fields.emailId='';
   
    }

}


validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

   

    if (!fields["emailId"]) {
      formIsValid = false;
      errors["emailId"] = "*Please enter your email-ID.";
    }

    if (typeof fields["emailId"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["emailId"])) {
        formIsValid = false;
        errors["emailId"] = "*Please enter valid email-ID.";
      }
    }

  
    this.setState({
      errors: errors
    });
    return formIsValid;


  }


    render(){
       return(
        <footer className="footer-area">
            <div className="letter-box">
                <Container>
                        <form method="post"  name="newsLetterForm"  onSubmit= {this.handleSubmit} >
                        <Row>
                        <Col md="3" className="newsLetterPos">
                         <h1 class="letter-side">NEWSLETTER</h1>
                        </Col>
                        <Col md="6" className="topAlign">
                            <label className="labelValue">
                                <input className="emailTextField" name="emailId" placeHolder="Enter your email" type="text" value={this.state.fields.emailId} onChange={this.handleChange} />
                                <div className="errorMsg">{this.state.errors.emailId}</div>
                            </label>                        
                            <div class="border">
                            </div>
                        </Col>
                        <Col md="3">
                            <button class="sign-in-button" type="submit" value="Submit">
                                SUBMIT
                            </button>
                        </Col>
                        </Row>
                        </form>
                    
                </Container>
            </div>
            <Container>
                <Row className="footer-row">
                    <Col lg="4" md="8" sm="4">
                        <div className="single-footer-widget">
                          <p>We love to give something to the society and we decided it to be good health. We provide you with a hassle-free digital experience.</p>
                        </div> 
                    </Col>
                    <Col lg="6" sm="4" className="content-box">
                        <span>
                            <i className="far fa-user-md" />
                        </span>
                        <div className="content">
                            <span id="mail">Mail Us</span>
                            <p id="footer-para">wegomeds@gmail.com</p>
                        </div>
                        <span>
                            <i className="fas fa-stethoscope" />
                        </span>
                        <div className="content2">
                            <span>Since Covid-19 Pandemic</span>
                            
                        </div>
                    </Col>
                    <Col lg="2" sm="4">
                        <div className="icons" style={{"display":"flex"}}>

                                   <div  className="social-icon">
                                      <a className="iconColor" href="https://www.facebook.com/Wegomeds-108976368118134/" target="_blank"> 
                                        <i className="fab fa-facebook-f"></i>
                                      </a>  
                                    </div>
                                    <div  className="social-icon">
                                    <a className="iconColor" href="https://mobile.twitter.com/wegomeds" target="_blank"> 
                                       <i className="fab fa-twitter"></i>
                                    </a> 
                                    </div>
                                    <div className="social-icon">
                                    <a className="iconColor" href="https://www.instagram.com/invites/contact/?i=1v80roejnhzga&utm_content=m8zszrv" target="_blank"> 
                                    <i className="fab fa-instagram"></i>
                                    </a>
                                 </div>
                                 <div className="social-icon">
                                    <a className="iconColor" href="https://wa.me/+919047186868" target="_blank">

                                    <i className="fab fa-whatsapp" />
                                    </a>
                                 </div>
                          
                          
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="text-center">
                <Row>
                    <Col md="12" sm="12" xs="12">
                    <p class="copyright-text">Copyright &copy; 2021 All Rights Reserved by MSCODEBOX </p>
                    </Col>
                </Row>
            </Container>
      </footer>
       );
    }

}


function mapDispatchToState(dispatch){
    return bindActionCreators(
      {
         // submitFormBuyProduct: submitFormBuyProduct,
         submitNewsLetter:submitNewsLetter
      },
      dispatch
    );
  };
  function mapStateToProps ( state ) {
      return {
        newsLetterResponse:state.newsLetterResponse
        //  resultProductArray: state.updatedbuyproductArray
          //reusltFormSubmitResponse:state.buyProductFormSubmitResponse
         };
    }
    
   
  export default connect(
      mapStateToProps,
      mapDispatchToState
    )(Footer)





