import React, { Component,useState,useEffect,useRef } from 'react';
import { 
  Popover,
  Tooltip,
  Modal,
  Col
} from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import BootstrapTable,{ TableHeaderColumn} from 'react-bootstrap-table-next';
import InputSpinner from 'react-bootstrap-input-spinner';
import "../../App.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateBuyProductArray } from '../../action/app.action';
import ValidationForm from "../Modal/Form";

const PurchaseItemModal = (props)=> {

const handleShow = () => {
}
const handleClose = () => {
  props.setClose(false)
}
    return (
       <div className="purchaseProductDiv">
          <Modal dialogClassName="my-modal" size="lg" show={props.isVisible} onHide={handleClose} className="custom-modal-style buyProduct">
             <Modal.Header closeButton>
               <Modal.Title className="modalTitle">
               
               <i className="fas fa-shopping-cart"></i>Thank you for your Order!!!

                  
                 </Modal.Title>
             </Modal.Header>
             <Modal.Body>
                <ValidationForm  closeModal={handleClose}/>
             </Modal.Body>
          </Modal>
        </div>
    )
}



export default (PurchaseItemModal);

