/*export const PRODUCT_CATEGORIES = 'PRODUCT_CATEGORIES';
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const IS_LOGIN = 'IS_LOGIN';
export const ADD_WORKER = "ADD_WORKER";
export const GET_PRODUCT= "GET_PRODUCT";*/
export const PAGE_CONTENT_LIST='PAGE_CONTENT_LIST';
export const AMBULANCE_LIST='AMBULANCE_LIST';
export const BLOODBANK_LIST='BLOODBANK_LIST';
export const PRODUCT_LIST='PRODUCT_LIST';
export const UPDATEDBUYPRODUCT_ARRAY_LOCALSTORE='UPDATEDBUYPRODUCT_ARRAY_LOCALSTORE';
export const UPDATEDRENTALPRODUCT_ARRAY_LOCALSTORE='UPDATEDRENTALPRODUCT_ARRAY_LOCALSTORE';
export const USERFORMDATA_IN_STORE='USERFORMDATA_IN_STORE';
export const NEWSLETTER_RESPONSE='NEWSLETTER_RESPONSE';
export const CONTACTUS_RESPONSE='CONTACTUS_RESPONSE';
export const PURCHASEORDER_RESPONSE='PURCHASEORDER_RESPONSE';
export const SERVICE_CONTENT_RESPONSE='SERVICE_CONTENT_RESPONSE';
