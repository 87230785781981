//const Host = 'http://localhost:32775/api/v1';
const Host = 'https://admin.wegocareplus.com/api/v1'; 
// const Host = '/api/v1';
export const URL ={
       /* "login" : `${Host}/login`,
        "registerWorker":`${Host}/register`,
        'customerLists': `${Host}/get_customer`,
        "getproducts": `${Host}/get_product`*/
        "trackVisitorsIP":'https://geolocation-db.com/json/ef6c41a0-9d3c-11eb-8f3b-e1f5536499e7',
        "storeVisitorsIP":`${Host}/ip`,
        "ambulanceList":`${Host}/ambulance`,
        "bloodBankList":`${Host}/bloodbank`,
        "submitPurchaseOrderAPI":`${Host}/buyProduct`,
        "submitNewsLetterAPI":`${Host}/newsletter`,
        "submitContactUsAPI":`${Host}/contact`,
        "productListAPI":`${Host}/productLists`,
        "pageContentAPI":`${Host}/pageContent`,
        "serviceContentResponseAPI":`${Host}/service-content`,
    }

   