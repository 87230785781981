import React, { Component } from 'react';
import Img from '../../assets/logo1.png';
import { MenuItems } from "./MenuItem";
import './Header.css';
import { LinkContainer } from "react-router-bootstrap";
import  Navbar  from 'react-bootstrap/Navbar';
import { Col, Row, Container, Nav } from 'react-bootstrap';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getProductList,getPageContents,getServiceContent,trackVisitorsIPaddress } from '../../action/app.action'
class Header extends Component{
constructor(props){
  super(props);
  this.checkActive = this.checkActive.bind(this);
}
 checkActive (e){
  console.log("hi");
}; 
componentDidMount(){
this.props.getProductList();
this.props.getPageContents();
this.props.getServiceContent();
this.props.getVisitorsIP();
//api call
}
    render(){
       return(
        <section className="navbar-section">
        <nav className="side-logo">
          <Container fluid>
            <Row>
                <Col md="2" lg="2">
                    <div className="side-img text-center">
                    <img src={Img} alt="logo"/>
                    </div>
                </Col>
                <Col md="10" sm="10" lg="10">
                    <div className="info">
                    <a className="joinHref"  href="https://wegomeds.com/wecare#contact" target="_blank">
                        <div className="info-left">
                            <div className="box shadow-sm">
                                <span className="contactLink">Join Us</span>
                            </div>
                        </div>
                    </a>    
                        <div className="info-right">
                            <div className="content-right social-icon">
                            <i className="fas fa-envelope" />
                            </div>
                            <div className="info-last">
                            <div className="widget-heading">Mail Us</div>
                            <div className="widget-subheading">wegomeds@gmail.com</div>
                            </div>
                            <div className="content-right social-icon">
                            <a className="callUS" href="tel:+919047186868"><i className="fas fa-mobile-alt" /> </a>
                            </div>
                            <div className="info-last-middle">
                            <div className="widget-heading">Call Us</div>
                            <div className="widget-subheading">+91 9047186868</div>
                            </div>
                            <div className="content-right social-icon">
                            <i className="fas fa-map-marker-alt" />
                            </div>
                            <div className="info-last" id="location">
                            <div  className="widget-heading ">Location</div>
                            <a  href="https://goo.gl/maps/9YT768f4hr5LXnNF6" target="_blank">

                            <div className="widget-subheading">Click here</div></a>
                            </div>
                     </div>   
                     </div> 
                     <Navbar expand="lg">
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                          <Nav className="test">
                            {MenuItems.map((item,index) => {
                              return(
                                <LinkContainer className="general" exact={true} to={item.url}>
                                     <Nav.Link    to={item.url} activeClassName="current" key={index} >{item.title}
                                       </Nav.Link>
                                </LinkContainer>
                                      
                                    )
                                   })
                            }
                            </Nav>
                            <Nav className="ml-auto paddingRt">
                            <span className="navbar-text">
                                    <div className="social-icon">
                                      <a className="iconColor" href="https://www.facebook.com/Wegomeds-108976368118134/" target="_blank"> 
                                        <i className="fab fa-facebook-f"></i>
                                      </a>  
                                    </div>
                                    <div  className="social-icon">
                                    <a className="iconColor" href="https://mobile.twitter.com/wegomeds" target="_blank"> 
                                       <i className="fab fa-twitter"></i>
                                    </a> 
                                    </div>
                                    <div className="social-icon">
                                    <a className="iconColor" href="https://www.instagram.com/invites/contact/?i=1v80roejnhzga&utm_content=m8zszrv" target="_blank"> 
                                    <i className="fab fa-instagram"></i>
                                    </a>
                                    </div>
                                    <div className="social-icon">
                                    <a className="iconColor" href="https://wa.me/+919047186868" target="_blank">
                                    <i className="fab fa-whatsapp"></i></a>    
                                    </div>
                              </span>
                            </Nav>
                        </Navbar.Collapse>
                      </Navbar>
                </Col>
            </Row>
          </Container>
        </nav>
      </section>
       );
    }

}
const mapStateToProps = (state) => {
  return {
  
  };
};

const mapDispatchToState = (dispatch) => {
  return bindActionCreators(
     {
      getPageContents:getPageContents,
      getProductList:getProductList,
      getServiceContent:getServiceContent,
      getVisitorsIP:trackVisitorsIPaddress
    },
    dispatch
  );
};


export default connect(
  mapStateToProps,
  mapDispatchToState
)(Header);
