
import React, { useEffect, useState, useRef,Video } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import './Home.css';
import Banner from '../../assets/side-logo1.png';
import ServiceImg from '../../assets/service.png';
import poster1 from '../../assets/poster.jpeg';
import Video1 from "../../assets/wegoo1.mp4";
import { Col, Row, Container } from 'react-bootstrap';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getPageContents } from '../../action/app.action';
import ReactPlayer from 'react-player'
const Home = (props)=>{
const [homePageContentArray, setHomePageContentArray] = useState([]);
const vidRef = useRef(null);
  const handlePlayVideo = () => {
    
    vidRef.current.play();
    vidRef.current.muted=false;
    vidRef.current.defaultMuted=false;

  }
useEffect(() => { 
    handlePlayVideo();
},[props.getPageContentValues]);

const renderMyProp=(val)=>{
    let pageContent;
    props.getPageContentValues?.map((category) => {
        if(category.pageName == "HomePage"){
            category.pageContent.map((contents)=>{ 
                if(contents.title==val){
                 console.log(contents.content)
                 pageContent=contents.content;
                   
              }
            })
        }
    })
    return {__html: pageContent};
}




    // useState
    // useEffect (initial, Update, destory)

       return(
        <section >
            <Row className="marginRow">
            <Col lg="8" className="no-padding" >
            <div className="banner-area relative" id="home">
            <Container>
                <Row className="align-items-center">
                    <Col  lg="6">
                        <div className="item-img no-padding d-none d-sm-block">
                             <img className="img-fluid" src={Banner} /> 
                        </div>
                    </Col>
                    <Col  lg="6"  >
                        <div className="banner-content">
                                <span className="wegomeds">
                                    WEGOMEDS HEALTH CARE
                                </span><br/>
                                <span className="wegomeds1">
                                ....Let's Build Healthy Generation...!
                                </span>
                        </div>
                    </Col>
                </Row>
            </Container>
            </div>
            </Col>
            <Col lg="4" className="no-padding">
          
           
          {/* <video  className="videoFile"  src={Video1} controls autoPlay></video>  */}
<video className="videoFile" src={Video1} type="video/mp4" ref={vidRef} controls>
      <source  />
    </video>
          

             {/* <a href="https://wegomeds.com/" target="_blank"><img className="img-fluid" src={poster1} /></a>  */}
            </Col>
            </Row>
            <div className="about-us" id="about">
            <Container>
                <Row>
                    <Col lg="12">
                        <div className="about-title text-center" id="about-heading" >
                           
                       <p className="aboutPTag">
                       <div dangerouslySetInnerHTML={renderMyProp("aboutus")}></div>
                       </p>
                  
                        </div>          
                    </Col>
                </Row>
             </Container>
            </div>

            <div className="outline-box">
            <Container>
                <Row>
                    <Col lg="8" md="8">
                            <h5 style={{color:"white"}}> To be the first choice in the health-oriented community by providing quality health care services and products.</h5>
                            <p>We commit to give you compassionate patient-centric care that goes beyond expectation.</p>
                    </Col>
                    <Col lg="4" md="4">
                            <div className="button-box">
                            <Link to="/shop" className="btn btn-primary shopeNow">Shop Now</Link>
                            </div>
                    </Col>
                </Row>
             </Container>
            </div>

            <div className="service">
                <div className="service-inner">
                <Container>
                    <Row>
                        <Col md="5">
                        <img className="img-fluid img-center" src={ServiceImg} />
                        </Col>
                        <Col md="7">
                        <div dangerouslySetInnerHTML={renderMyProp("services")}></div>

                        </Col>
                    </Row>
                </Container>        
                </div>
            </div>
        </section> 
       );

}

const mapStateToProps = (state) => {
    return {
     getPageContentValues: state.pageContentList,
    
    };
 };
 
 const mapDispatchToState = (dispatch) => {
    return bindActionCreators(
       {
         
      },
      dispatch
    );
 };
 
 export default connect(
   mapStateToProps,
   mapDispatchToState
 )(Home);
 





