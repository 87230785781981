import React, { Component,useState,useEffect } from 'react';

import './Services.css';
import { Container, Row, Col, Tab, Nav,Image,Button } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
const Services = (props)=> {
    const imageHttpUrl="https://admin.wegocareplus.com/";
    const [key, setKey] = useState('first');
   const renderContent=(val)=>{
     return {__html: val};
}
  

   
    //Horizontal tab
    const [currentTab, handleClick] = useState(0);

    const [currentContentInfo,setCurrentContentInfo]=useState({"content":"","imageURL":""});

    useEffect(() => {
      clickLeftSideMenu("Homecare",0)
      
    },[]);

    const clickLeftSideMenu=(type,val)=>{
      handleClick(val);
      if(type=="Homecare"){
        setCurrentContentInfo({"title":props.serviceContentResponse[props.serviceContentResponse.findIndex(p=>p.tabTitle=="Home care & Nursing")].tabContents[val].title,"content":props.serviceContentResponse[props.serviceContentResponse.findIndex(p=>p.tabTitle=="Home care & Nursing")].tabContents[val].content,"imageURL":props.serviceContentResponse[props.serviceContentResponse.findIndex(p=>p.tabTitle=="Home care & Nursing")].tabContents[val].imgUrl});
      }
      else if(type == "Complementary and Alternative Medicine"){
        setCurrentContentInfo({"title":props.serviceContentResponse[props.serviceContentResponse.findIndex(p=>p.tabTitle=="Complementary and Alternative Medicine")].tabContents[val].title,"content":props.serviceContentResponse[props.serviceContentResponse.findIndex(p=>p.tabTitle=="Complementary and Alternative Medicine")].tabContents[val].content,"imageURL":props.serviceContentResponse[props.serviceContentResponse.findIndex(p=>p.tabTitle=="Complementary and Alternative Medicine")].tabContents[val].imgUrl});
      }
      else if(type == "Wellness Care"){
        setCurrentContentInfo({"title":props.serviceContentResponse[props.serviceContentResponse.findIndex(p=>p.tabTitle=="Wellness Care")].tabContents[val].title,"content":props.serviceContentResponse[props.serviceContentResponse.findIndex(p=>p.tabTitle=="Wellness Care")].tabContents[val].content,"imageURL":props.serviceContentResponse[props.serviceContentResponse.findIndex(p=>p.tabTitle=="Wellness Care")].tabContents[val].imgUrl});

      }
    }

    return (
        <Container  style={{ width: "auto" }}>
          
        <Tab.Container activeKey={key} onSelect={key => setKey(key)}>
          <Row style={{background: "linear-gradient( to bottom, mediumseagreen, #079669)", color:"#FFF" }}>
            <Col className={"colTxt,txtCenter",key === 'first' ? "txtUnderLine" : "rmvUnderLine"} >
                <Nav.Link
                eventKey="first" onClick={() => clickLeftSideMenu("Homecare",0)}>
                       Home Care & Nursing
                 </Nav.Link>
                
            </Col>
           <Col className={"colTxt,txtCenter",key === 'second' ? "txtUnderLine" : "rmvUnderLine"} >
              <Nav.Link eventKey="second" onClick={() => clickLeftSideMenu("Complementary and Alternative Medicine",0)}>
              Complementary and Alternative Medicine
              </Nav.Link>
            </Col>
              <Col className={"colTxt,txtCenter",key === 'third' ? "txtUnderLine" : "rmvUnderLine"} >
              <Nav.Link eventKey="third" onClick={() => clickLeftSideMenu("Wellness Care",0)}>
              wellness Care
              
              </Nav.Link>
            </Col> 
          </Row>

          <Tab.Content>
            <Tab.Pane eventKey="first">
              <Row>
              <div className="service-tab">
                    <div id="ex3"  className="tab leftMenu" >
                     {props.serviceContentResponse && props.serviceContentResponse[props.serviceContentResponse.findIndex(p=>p.tabTitle=="Home care & Nursing")
].tabContents.map((button, i) => (
                        <button value={i} key={button.title} className={"tablinks", currentTab==i ? "activeTab" : ""} onClick={() => clickLeftSideMenu("Homecare",i)}>{button.title}</button>
                        )
                        )
                    }  
                    </div>
                    
                    <div className="tabcontent">

                    <React.Fragment>
                            <h3 className="text-center titleConfig">{currentContentInfo.title}</h3>
                            <Row>
                              <Col md="4" lg="4" className="text-center">
                              <img className="imgSize" src ={imageHttpUrl+currentContentInfo.imageURL} />
                              </Col>
                              <Col md="8" lg="8">
                                  {/* <p className="contentConfig">{currentContentInfo.content}</p> */}

                                  <div dangerouslySetInnerHTML={renderContent(currentContentInfo.content)}></div>
                              </Col>
                            </Row>
                        </React.Fragment>

                  
                    </div>
                </div>
                   
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <Row>
              <div  className="service-tab">
                     <div id="ex3"  className="tab leftMenu" >
                    {props.serviceContentResponse && props.serviceContentResponse[props.serviceContentResponse.findIndex(p=>p.tabTitle=="Complementary and Alternative Medicine")
].tabContents.map((button, i) => (
                        <button value={i} key={button.title} className={"tablinks", currentTab==i ? "activeTab" : ""} onClick={() => clickLeftSideMenu("Complementary and Alternative Medicine",i)}>{button.title}</button>
                        )
                        )
                    }  
                    </div> 
                    <div className="tabcontent">

 <React.Fragment>
        <h3 className="text-center titleConfig">{currentContentInfo.title}</h3>
        <Row>
          <Col md="4" lg="4" className="text-center">
          <img className="imgSize" src ={imageHttpUrl+currentContentInfo.imageURL} />
          </Col>
          <Col md="8" lg="8">
              <div dangerouslySetInnerHTML={renderContent(currentContentInfo.content)}></div>
          </Col>
        </Row>
    </React.Fragment>


</div>
                </div>

              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              <Row>
              <div  className="service-tab">
                   <div id="ex3"  className="tab leftMenu" >
                  {props.serviceContentResponse && props.serviceContentResponse[props.serviceContentResponse.findIndex(p=>p.tabTitle=="Wellness Care")
].tabContents.map((button, i) => (
                        <button value={i} key={button.title} className={"tablinks", currentTab==i ? "activeTab" : ""} onClick={() => clickLeftSideMenu("Wellness Care",i)}>{button.title}</button>
                        )
                        )
                    }  
                    </div> 

                    <div className="tabcontent">
                     <React.Fragment>
        <h3 className="text-center titleConfig">{currentContentInfo.title}</h3>
        <Row>
          <Col md="4" lg="4" className="text-center">
          <img className="imgSize" src ={imageHttpUrl+currentContentInfo.imageURL} />

          </Col>
          <Col md="8" lg="8">
              <div dangerouslySetInnerHTML={renderContent(currentContentInfo.content)}></div>
          </Col>
        </Row>
        {currentContentInfo.title === 'WELLNESS TOUR' &&

       <Row className="text-right">   
          <Col sm="12" md="12" lg="12"><a href="https://admin.wegocareplus.com/uploads/images/Time%20sheet%20for%20Wellness%20camp.pdf" target="_blank">
          <Button variant="success">DOWNLOAD PDF <i class="fa fa-download" aria-hidden="true"></i></Button>{' '}</a>
          </Col>
        </Row>
         }
    </React.Fragment>

                    </div>
                   
                </div>

              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>
    )
}


const mapStateToProps = (state) => {
  return {
  
   serviceContentResponse:state.serviceContentResponse
  };
};

const mapDispatchToState = (dispatch) => {
  return bindActionCreators(
     {
    
    },
    dispatch
  );
};

export default connect(
 mapStateToProps,
 mapDispatchToState
)(Services);

