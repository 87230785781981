import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Col, Row, Container } from 'react-bootstrap';
import validator from "validator";

import {
  Textbox,
  Textarea,
  Radiobox,
  Checkbox,
  Select
} from "react-inputs-validation";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { submitContactUs } from '../../action/app.action';
import Img1 from '../../assets/icon/help.png';

import "./Contact.css";



class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email:"", 
      phone:"",
      comment: "",
      hasNameError: true,
      hasEmailError:true,
      hasMessageError: true,
      validate: false
    };
    this.validateForm = this.validateForm.bind(this);
    this.greenSpan = {
      'color': 'green',
      'fontWeight': 'bold',
      'width': '80%',
      'float': 'left'
    }
    
  }

  toggleValidating(validate) {
    this.setState({ validate });
  }

  validateForm(e) {
    e.preventDefault();
    this.toggleValidating(true);
    const {
      hasNameError,
      hasEmailError,
      hasMessageError,
      hasContactNumberError
    } = this.state;
    if (
      !hasNameError &&
      !hasEmailError &&
      !hasContactNumberError
    ) {
     
      this.props.submitContactUs(this.state);

     
      // console.log(this.props.submitContactUsResponse);
      // this.state.name="";
      // this.state.email="";
      // this.state.phone="";
      // this.state.comment="";
     
    }
  }

  render() {
    const {
      name,
      email,
      comment,
      phone,
      validate
    } = this.state;
    return (
        <section className="About-us bg-light">
        <Container>
             <div className="about-box-shadow shadow-sm heightPt">
                        <Row className="marginAuto">
                            <Col className="paddingRow" md="6" lg="6" sm="12">
                            <form className="contactForm" onSubmit={this.validateForm}>
                            <label>Full Name</label>    
                        <Textbox
                        className="contactInput"
                        attributesInput={{
                          id: "name",
                          name: "Name",
                          type: "text",
                          placeholder: ""
                        }}
                        value={name} // Optional.[String].Default: "".
                        disabled={false} // Optional.[Bool].Default: false.
                        validate={validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                        validationCallback={res =>
                          this.setState({ hasNameError: res, validate: false })
                        } // Optional.[Func].Default: none. Return the validation result.
                        onChange={(name, e) => {
                          this.setState({ name });
                         
                        }} // Required.[Func].Default: () => {}. Will return the value.
                        onBlur={e => {
                         
                        }} // Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                        validationOption={{
                          firstName: "name", // Optional.[String].Default: "". To display in the Error comment. i.e Please enter your ${name}.
                          check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                          required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                         
                        }}
                      />
                      

                      <label className="marginTxt">Contact Number</label>  
<Textbox
              attributesInput={{
                id: "phone",
                placeholder: "",
                type: "text"
              }}
              value={phone} //Optional.[String].Default: "".
              validate={validate}
              validationCallback={res =>
                this.setState({
                  hasContactNumberError: res,
                  validate: false
                })
              }
              onChange={(phone, e) => {
                this.setState({ phone });
               
              }} //Required.[Func].Default: () => {}. Will return the value.
              onBlur={e => {}} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
              validationOption={{
                name: "Contact Number", //Optional.[String].Default: "". To display in the Error comment. i.e Please enter your {name}.
                check: true, //Optional.[Bool].Default: true. To determin if you need to validate.
                required: true, //Optional.[Bool].Default: true. To determin if it is a required field.
                customFunc: phone => {
                  if (validator.isMobilePhone(phone, "en-US")) {
                    return true;
                  } else {
                    return "is not a valid phone number";
                  }
                }
              }}
            />


<label className="marginTxt">Email ID</label>  
                    <Textbox
              attributesInput={{
                id: "email",
                placeholder: "",
                type: "text"
              }}
              className="contactInput"
              value={email} //Optional.[String].Default: "".
              validate={validate}
              validationCallback={res =>
                this.setState({
                  hasEmailError: res,
                  validate: false
                })
              }
              onChange={(email, e) => {
                this.setState({ email });
              }} //Required.[Func].Default: () => {}. Will return the value.
              onBlur={e => {}} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
              validationOption={{
                name: "email", //Optional.[String].Default: "". To display in the Error comment. i.e Please enter your {name}.
                check: true, //Optional.[Bool].Default: true. To determin if you need to validate.
                required: true, //Optional.[Bool].Default: true. To determin if it is a required field.
                customFunc: email => {
                  const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                  if (reg.test(String(email).toLowerCase())) {
                    return true;
                  } else {
                    return "please enter valid email address";
                  }
                }
              }}
            />

                    <label className="marginTxt">Message</label>
                        <Textarea   
                        attributesInput={{
                          id: "comment",
                          name: "comment",
                          placeholder: ""
                          // maxLength: '10',
                          // cols: '10',
                          // rows: '10',
                        }}
                        value={comment} // Optional.[String].Default: "".
                        disabled={false} // Optional.[Bool].Default: false.
                        placeholder="" // Optional.[String].Default: "".
                      
                      
                        onChange={(comment, e) => {
                          this.setState({ comment });
                        
                        }} // Required.[Func].Default: () => {}. Will return the value.
                        onBlur={e => {
                         
                        }} // Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                      
                        validationOption={{
                          name: "comment", // Optional.[String].Default: "". To display in the Error comment. i.e Please enter your ${name}.
                          check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                          required: false, // Optional.[Bool].Default: true. To determin if it is a required field.
                          type: "string" // Optional.[String].Default: "string". Validation type, options are ['string', 'number'].
                       
                        }}
                      />            
                           <button className="submitBtn btnMargin" type="submit">Submit</button>      
                            </form>
                            </Col>
                            <Col lg="6" md="6" sm="12"  className="paddingContactImage">
                            
                                    {/* <img className="img-fluid" src={Img1}  /> */}
                                    <h3 className="addHead">ADDRESS:</h3>
                                    <br></br>
                                    <h5>#1/219 abinandan Nagar,</h5> 
                                    <h5> 3rd main road, Nanmangalam,</h5>
                                    <h5>Landmark:Next street to Lakshmi Mahal,</h5>
                                    <h5>Chennai, Tamilnadu-600129</h5>
                                    <br></br>
                                    <h3 className="addHead">TO JOIN WEGO</h3> 
                                    <span style={this.greenSpan}> (Nurses / Doctors / Physio / Nature Therapist & Health Care ) </span>
                                    
                                    <br></br>
                                    <a className="joinContact" href="tel:+919047286868"><i class="Phone is-animating"><span className="phoneNumTxt">+91-9047286868</span></i></a>
                                    <br></br>
                                    <h3 className="addHead">For Inquiry / Service / Complaints </h3>
                                    <a className="joinContact" href="tel:+919047186868"><i class="Phone is-animating"><span className="phoneNumTxt">+91-9047186868</span></i></a>
                                                                     
                            </Col>
                        </Row> 
                </div>
        </Container>
  </section>
    
    );
  }
}
function mapDispatchToState(dispatch){
    return bindActionCreators(
      {
        submitContactUs:submitContactUs
      },
      dispatch
    );
  };
  function mapStateToProps ( state ) {
      return {
         submitContactUsResponse:state.countactusFormResponse
         };
    }
    
export default connect(
    mapStateToProps,
    mapDispatchToState
  )(Contact)