
import React, { Component,useEffect } from 'react';
import './About.css';
import { Col, Row, Container } from 'react-bootstrap';
import Img1 from '../../assets/icon/about us.png';
import Img2 from '../../assets/vision1.png';
import Img3 from '../../assets/mission2.png';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getPageContents } from '../../action/app.action';

const About = (props)=>{  
useEffect(() => {
    console.log(props.getPageContentValues);
        
});
const renderMyProp=(val)=>{
    let pageContent;
    props.getPageContentValues?.map((category) => {
        if(category.pageName == "About Us"){
            category.pageContent.map((contents)=>{ 
                if(contents.title==val){
                 pageContent=contents.content;
                   
              }
            })
        }
    })
    return {__html: pageContent};
}
    return(
        <section className="About-us bg-light">
            <Container>
                <Row>
                    <Col lg="12">
                    <div className="about-box-shadow shadow-sm">
                            <Row>
                                <Col md="4">
                                    <img className="img-fluid about-logo" src={Img1}  />
                                </Col>
                                <Col md="8">
                                <div className="about-para" dangerouslySetInnerHTML={renderMyProp("about us")}></div>
                                </Col>
                            </Row>    
                            <Row className="target">
                                <Col md="6">
                                    <div className="vission-mission d-flex">
                                        <div className="vision-img" style={{width: '50%'}}>
                                        <img className="img-fluid" src={Img2} />
                                        </div>
                                        <div className="vision-para" dangerouslySetInnerHTML={renderMyProp("vision")}>

                                 </div>      
                                    </div>
                                </Col>
                                <Col md="6">
                                        <div className="vission-mission d-flex">
                                            <div className="mission-img" style={{width: '50%'}}>
                                            <img className="img-fluid" src={Img3} />
                                            </div>
                                            <div className="vision-para"  dangerouslySetInnerHTML={renderMyProp("mission")}>
                                           

                                            </div>
                                        </div>
                                </Col>
                            </Row>
                        
                    </div>
                    </Col>
                </Row>
            </Container>
      </section>
       )

}
const mapStateToProps = (state) => {
    return {
     getPageContentValues: state.pageContentList,
    
    };
 };
 
 const mapDispatchToState = (dispatch) => {
    return bindActionCreators(
       {
         
      },
      dispatch
    );
 };
 
 export default connect(
   mapStateToProps,
   mapDispatchToState
 )(About);
 






