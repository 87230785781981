import React, { Component,useState,useEffect } from 'react';
import { 
  Popover,
  Tooltip,
  Modal
} from 'react-bootstrap';
import BootstrapTable,{ TableHeaderColumn} from 'react-bootstrap-table-next';


import paginationFactory from "react-bootstrap-table2-paginator";


import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAmbulanceList } from "../../action/app.action";

const AmbulanceModal = (props)=> {
  const [ambulanceList, setAmbulanceList] = useState([]);
  useEffect(() => {
  //  setAmbulanceList(props.ambulanceList);
  props.getAmbulanceList(); 
  }, []);


const handleShow = () => {
}
const handleClose = () => {
  props.setClose(false)
}

const columns = [{
  dataField: 'service_name',
  text: 'Name'
},
{
  dataField: 'service_phone',
  text: 'Phone Number'
},
{
  dataField: 'service_address',
  text: 'Address'
},
{
  dataField: 'service_email',
  text: 'Email-Id'
}
];

    return (
       <div className="ambulanceDiv">
         {console.log(props)}
          <Modal size="lg" show={props.isVisible} onHide={handleClose} className="custom-modal-style">
             <Modal.Header closeButton>
               <Modal.Title>Ambulance Service Contact Number</Modal.Title>
             </Modal.Header>
             <Modal.Body>
             <BootstrapTable
        keyField="sno"
        data={props.ambulanceList}
        columns={columns}
        pagination={paginationFactory({ sizePerPage: 5 })}
        
      />
             </Modal.Body>
          </Modal>
        </div>
    )
}



const mapStateToProps = (state) => {
  return {
    ambulanceList: state.ambulanceList,
  };
};

const mapDispatchToState = (dispatch) => {
  return bindActionCreators(
    {
      getAmbulanceList: getAmbulanceList,
    },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToState
)(AmbulanceModal);
