import React, { Component } from "react";
import { Popover, Tooltip, Modal, Col } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import ReactDOM from "react-dom";
import { Textbox, Textarea, Radiobox, Checkbox, Select } from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import validator from "validator";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BadgeMessageService } from '../../badge_services/badgeMessageService'

import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import { submitFormBuyProductStoreValue, submitPurchaseOrder, updateBuyProductArrayLocalStore, updateRentProductArrayLocalStore } from "../../action/app.action";

import moment from 'moment';
class ValidationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      contactNumber1: "",
      contactNumber2: "",
      address: "",
      email: "",
      pinCode: "",
      knowOurServiceBy: "",
      refContactName: "",
      refcontactNumber1: "",
      hasNameError: true,
      hasDescriptionError: true,
      hasContactNumber1Error: true,
      hasContactNumber2Error: true,
      hasEmailError: true,
      hasPinCodeError: true,
      validate: false,

      productSummaryList: [],
      netAmount: 0,
      isUserFormFilled: false,
      postcodeValidators: false,
      currentActiveTab: localStorage.getItem('tabName'),
      refChecked: false,
      imageHttpUrl: "https://admin.wegocareplus.com/"
    };
    this.validateForm = this.validateForm.bind(this);
    this.proceedCheckOutButtonClick = this.proceedCheckOutButtonClick.bind(this);
    this.clickContinueShoppingBtn = this.clickContinueShoppingBtn.bind(this);
    this.clickContinueShopping = this.clickContinueShopping.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);

  }
  onChangeService(e) {
    this.setState({
      knowOurServiceBy: e.target.value
    })
  }

  handleCheckBoxChange() {
    this.setState({
      refChecked: !this.state.refChecked
    })
  }
  toggleValidating(validate) {
    this.setState({ validate });
  }
  componentDidMount() {

    if (this.props.getUserFormValues != undefined) {

      this.setState({ isUserFormFilled: this.props.getUserFormValues.isUserFormFilled })

    }

    if (this.state.currentActiveTab == 'buy') {
      let finalAmount = 0;
      console.log(this.props.resultProductArrayBuy);
      this.props.resultProductArrayBuy.forEach(element => {
        if (element.productBuyingStatus == true) {
          this.state.productSummaryList.push(element);
        }
        if (element.totalAmount != undefined) {
          finalAmount = finalAmount + parseInt(element.totalAmount);
        }

      });
      this.setState({ netAmount: finalAmount })

    }
    else {

      let finalAmount = 0;
      console.log(this.props.resultProductArrayRent);
      this.props.resultProductArrayRent.forEach(element => {
        if (element.productBuyingStatus == true) {
          this.state.productSummaryList.push(element);
        }
        if (element.totalAmount != undefined) {
          finalAmount = finalAmount + parseInt(element.totalAmount);
        }

      });
      this.setState({ netAmount: finalAmount })

    }

  }

  validateForm(e) {
    e.preventDefault();
    this.toggleValidating(true);
    const {
      hasNameError,
      hasDescriptionError,
      hasContactNumber1Error,
      hasContactNumber2Error,
      hasEmailError,
      hasPinCodeError
    } = this.state;
    if (
      !hasNameError &&
      !hasDescriptionError &&
      !hasContactNumber1Error &&
      !hasEmailError &&
      !hasPinCodeError

    ) {
      this.props.storeFormValues(this.state); //post values in STORE used for another component

    }


  }

  clickContinueShoppingBtn(e) {
    this.props.closeModal();
  }

  proceedCheckOutButtonClick(e) {
    console.log(this.props.getUserFormValues);
    console.log(this.state.productSummaryList);
    let tempReqObj = {
      "personDetail": {
        "firstName": "",
        "lastName": "",
        "emailId": "",
        "address": "",
        "postalCode": "",
        "contactNumber1": "",
        "contactNumber2": "",
        "knowOurServiceBy": "",
        "referredBy": {
          "referenceName": "",
          "referenceContactNumber": ""
        }
      }, productDetail: [

      ]
    };
    tempReqObj.personDetail['firstName'] = this.props.getUserFormValues.firstName;
    tempReqObj.personDetail['lastName'] = this.props.getUserFormValues.lastName;
    tempReqObj.personDetail['emailId'] = this.props.getUserFormValues.email;
    tempReqObj.personDetail['address'] = this.props.getUserFormValues.address;
    tempReqObj.personDetail['postalCode'] = this.props.getUserFormValues.pinCode;
    tempReqObj.personDetail['knowOurServiceBy'] = this.props.getUserFormValues.knowOurServiceBy;
    tempReqObj.personDetail['contactNumber1'] = this.props.getUserFormValues.contactNumber1;
    tempReqObj.personDetail['contactNumber2'] = this.props.getUserFormValues.contactNumber2;
    tempReqObj.personDetail['referredBy']['referenceName'] = this.props.getUserFormValues.refContactName;
    tempReqObj.personDetail['referredBy']['referenceContactNumber'] = this.props.getUserFormValues.refcontactNumber1;

    this.state.productSummaryList.forEach(ele => {
      tempReqObj.productDetail.push(ele);
    })
    console.log(tempReqObj);

    this.props.submitPurchaseOrder(tempReqObj);
    this.props.closeModal();
    if (localStorage.getItem('tabName') === 'buy') {
      this.props.updateBuyProductArrayLocalStore([]);
    }
    else {
      this.props.updateRentProductArrayLocalStore([]);
    }

    BadgeMessageService.sendMessage(0);



  }
  clickContinueShopping(e) {
  }

  render() {
    const {
      firstName,
      lastName,
      contactNumber1,
      contactNumber2,
      email,
      pinCode,
      address,
      knowOurServiceBy,
      refContactName,
      refcontactNumber1,
      validate
    } = this.state;
    const rowStyle = {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      padding: "2%",
      fontSize: "14px"
    };
    const rowWrapperStyle = {
      display: "table",
      width: "100%"
    };
    const rowContainerStyle = {
      display: "table-cell",
      verticalAlign: "middle",
      borderBottom: "1px solid #e5e5e5"
    };
    const labelStyle = {
      display: "inline-block"
    };
    const labelContentStyle = {
      verticalAlign: "middle"
    };

    return (
      <div>
        <div className={this.state.isUserFormFilled ? 'hiddenFormCls' : 'showFormCls'} style={{ padding: "10px", border: "1px solid #e5e5e5" }}>

          <h5 className="headerFont">Please fill the form our executive will contact you shortly</h5>
          <form onSubmit={this.validateForm}>
            <Row>
              <Col lg="6" md="6" sm="6">
                <label>First Name <span className="requiredColor">*</span></label>
                <Textbox

                  attributesWrapper={{}}
                  attributesInput={{
                    id: "firstName",
                    name: "firstName",
                    type: "text",
                    placeholder: ""
                  }}
                  value={firstName} // Optional.[String].Default: "".
                  disabled={false} // Optional.[Bool].Default: false.
                  validate={validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                  validationCallback={res =>
                    this.setState({ hasNameError: res, validate: false })
                  } // Optional.[Func].Default: none. Return the validation result.
                  classNameInput="" // Optional.[String].Default: "".
                  classNameWrapper="" // Optional.[String].Default: "".
                  classNameContainer="" // Optional.[String].Default: "".
                  customStyleInput={{}} // Optional.[Object].Default: {}.
                  customStyleWrapper={{}} // Optional.[Object].Default: {}.
                  customStyleContainer={{}} // Optional.[Object].Default: {}.
                  onChange={(firstName, e) => {
                    this.setState({ firstName });
                    console.log(e);
                  }} // Required.[Func].Default: () => {}. Will return the value.
                  onBlur={e => {
                    console.log(e);
                  }} // Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                  // onFocus={(e) => {console.log(e);}} // Optional.[Func].Default: none.
                  // onClick={(e) => {console.log(e);}} // Optional.[Func].Default: none.
                  validationOption={{
                    firstName: "First Name", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                    check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                    required: true // Optional.[Bool].Default: true. To determin if it is a required field.

                  }}
                />
              </Col>
              <Col lg="6" md="6" sm="6">
                <label>Last Name</label>
                <Textbox
                  attributesWrapper={{}}
                  attributesInput={{
                    id: "lastName",
                    name: "lastName",
                    type: "text",
                    placeholder: ""
                  }}
                  value={lastName} // Optional.[String].Default: "".
                  disabled={false} // Optional.[Bool].Default: false.
                  validate={validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                  validationCallback={res =>
                    this.setState({ hasNameError: res, validate: false })
                  } // Optional.[Func].Default: none. Return the validation result.
                  classNameInput="" // Optional.[String].Default: "".
                  classNameWrapper="" // Optional.[String].Default: "".
                  classNameContainer="" // Optional.[String].Default: "".
                  customStyleInput={{}} // Optional.[Object].Default: {}.
                  customStyleWrapper={{}} // Optional.[Object].Default: {}.
                  customStyleContainer={{}} // Optional.[Object].Default: {}.
                  onChange={(lastName, e) => {
                    this.setState({ lastName });
                    console.log(e);
                  }} // Required.[Func].Default: () => {}. Will return the value.
                  onBlur={e => {
                    console.log(e);
                  }}
                  validationOption={{
                    lastName: "lastName", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                    check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                    required: false // Optional.[Bool].Default: true. To determin if it is a required field.

                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col lg="6" md="6" sm="6">
                <label>Contact Number 1 <span className="requiredColor">*</span></label>
                <Textbox
                  attributesInput={{
                    id: "contactNumber1",
                    placeholder: "",
                    type: "text"
                  }}
                  value={contactNumber1} //Optional.[String].Default: "".
                  validate={validate}
                  validationCallback={res =>
                    this.setState({
                      hasContactNumber1Error: res,
                      validate: false
                    })
                  }
                  onChange={(contactNumber1, e) => {
                    this.setState({ contactNumber1 });
                    console.log(e);
                  }} //Required.[Func].Default: () => {}. Will return the value.
                  onBlur={e => { }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                  validationOption={{
                    name: "Contact Number 1", //Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                    check: true, //Optional.[Bool].Default: true. To determin if you need to validate.
                    required: true, //Optional.[Bool].Default: true. To determin if it is a required field.
                    customFunc: contactNumber1 => {
                      if (validator.isMobilePhone(contactNumber1, "en-US")) {
                        return true;
                      } else {
                        return "is not a valid phone number";
                      }
                    }
                  }}
                />


              </Col>
              <Col lg="6" md="6" sm="6">
                <label>Contact Number 2 </label>
                <Textbox
                  attributesInput={{
                    id: "contactNumber2",
                    placeholder: "",
                    type: "text"
                  }}
                  value={contactNumber2} //Optional.[String].Default: "".
                  onChange={(contactNumber2, e) => {
                    this.setState({ contactNumber2 });
                    console.log(e);
                  }} //Required.[Func].Default: () => {}. Will return the value.
                  validate={false}
                  validationCallback={res =>
                    this.setState({
                      hasContactNumber2Error: res,
                      validate: false
                    })
                  }
                  onBlur={e => { }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                  validationOption={{
                    name: "Contact Number 2", //Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                    check: true, //Optional.[Bool].Default: true. To determin if you need to validate.
                    required: false, //Optional.[Bool].Default: true. To determin if it is a required field.
                    customFunc: contactNumber2 => {
                      if (validator.isMobilePhone(contactNumber2, "en-US")) {
                        return true;
                      } else {
                        return "is not a valid phone number";
                      }
                    }
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col lg="6" md="6" sm="6">
                <label>Email ID <span className="requiredColor">*</span></label>
                <Textbox
                  attributesInput={{
                    id: "email",
                    placeholder: "",
                    type: "text"
                  }}
                  value={email} //Optional.[String].Default: "".
                  validate={validate}
                  validationCallback={res =>
                    this.setState({
                      hasEmailError: res,
                      validate: false
                    })
                  }
                  onChange={(email, e) => {
                    this.setState({ email });
                    console.log(e);
                  }} //Required.[Func].Default: () => {}. Will return the value.
                  onBlur={e => { }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                  validationOption={{
                    name: "email", //Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                    check: true, //Optional.[Bool].Default: true. To determin if you need to validate.
                    required: true, //Optional.[Bool].Default: true. To determin if it is a required field.
                    customFunc: email => {
                      const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                      if (reg.test(String(email).toLowerCase())) {
                        return true;
                      } else {
                        return "please enter valid email address";
                      }
                    }
                  }}
                />
              </Col>
              <Col lg="6" md="6" sm="6">
                <label>Pin Code <span className="requiredColor">*</span></label>
                <Textbox
                  attributesInput={{
                    id: "pinCode",
                    name: "pinCode",
                    placeholder: "",
                    type: "text"
                  }}
                  value={pinCode} //Optional.[String].Default: "".
                  validate={validate}
                  validationCallback={res =>
                    this.setState({
                      hasPinCodeError: res,
                      validate: false
                    })
                  }
                  onChange={(pinCode, e) => {
                    this.setState({ pinCode });
                    console.log(e);
                  }} //Required.[Func].Default: () => {}. Will return the value.
                  onBlur={e => { }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                  validationOption={{
                    name: "PinCode", //Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                    required: false, //Optional.[Bool].Default: true. To determin if it is a required field.
                    customFunc: res => {
                      //Optional.[Func].Default: none. Custom function. Returns true or err message

                      if (res === "") {
                        this.setState({ hasError: true });
                        return "Please Enter PIN code";
                      }
                      let tempVal = postcodeValidator(res, 'IN');
                      console.log(tempVal)
                      if (tempVal == false) {
                        this.setState({ hasError: true });
                        return "Please Enter valid PIN";
                      }
                      this.setState({ hasError: false });
                      return true;
                    }
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col lg="12" md="12" sm="12" >
                <label>Address <span className="requiredColor">*</span></label>
                <Textarea
                  attributesWrapper={{}}
                  attributesInput={{
                    id: "address",
                    name: "address",
                    placeholder: ""
                    // maxLength: '10',
                    // cols: '10',
                    // rows: '10',
                  }}
                  value={address} // Optional.[String].Default: "".
                  disabled={false} // Optional.[Bool].Default: false.
                  placeholder="" // Optional.[String].Default: "".
                  validate={validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                  validationCallback={res =>
                    this.setState({
                      hasDescriptionError: res,
                      validate: false
                    })
                  } // Optional.[Func].Default: none. Return the validation result.
                  classNameInput="" // Optional.[String].Default: "".
                  classNameWrapper="" // Optional.[String].Default: "".
                  classNameContainer="" // Optional.[String].Default: "".
                  customStyleInput={{}} // Optional.[Object].Default: {}.
                  customStyleWrapper={{}} // Optional.[Object].Default: {}.
                  customStyleContainer={{}} // Optional.[Object].Default: {}.
                  onChange={(address, e) => {
                    this.setState({ address });
                    console.log(e);
                  }} // Required.[Func].Default: () => {}. Will return the value.
                  onBlur={e => {
                    console.log(e);
                  }} // Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                  // onFocus={(e) => {console.log(e);}} // Optional.[Func].Default: none.
                  // onClick={(e) => {console.log(e);}} // Optional.[Func].Default: none.
                  validationOption={{
                    name: "Address", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                    check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                    required: true, // Optional.[Bool].Default: true. To determin if it is a required field.
                    type: "string" // Optional.[String].Default: "string". Validation type, options are ['string', 'number'].

                  }}
                />

              </Col>
            </Row>
            <Row>
              <Col lg="6" md="6" sm="6" className="serviceDropDown">
                <br />
                How do you know our service?
                <select value={this.state.knowOurServiceBy} onChange={this.onChangeService.bind(this)} className="form-control">
                  <option value="SELECT">SELECT</option>
                  <option value="social media">SOCIAL MEDIA</option>
                  <option value="hospital">HOSPITAL</option>
                  <option value="friends/relatives">FRIENDS/RELATIVES</option>
                  <option value="brouchers/newspapaer">BROUCHERS/NEWSPAPER</option>
                </select>
              </Col>
              <Col lg="6" md="6" sm="6">

              </Col>
            </Row>
            <br />
            <span>Referred By : &nbsp; &nbsp; </span>
            {/* <div class="checkbox" style={{display:"inline"}}>
    <label>
      <input type="checkbox" checked={ this.state.refChecked } onChange={ this.handleCheckBoxChange } />
      <i class="input-helper"></i>
      
    </label>
  </div> */}
            <Row>

              <Col lg="6" md="6" sm="6">

                <label>Referer Name</label>
                <Textbox
                  attributesWrapper={{}}
                  attributesInput={{
                    id: "refContactName",
                    name: "refContactName",
                    type: "text",
                    placeholder: "Referer Full Name"
                  }}
                  value={refContactName} // Optional.[String].Default: "".
                  disabled={false} // Optional.[Bool].Default: false.
                  validate={false}

                  classNameInput="" // Optional.[String].Default: "".
                  classNameWrapper="" // Optional.[String].Default: "".
                  classNameContainer="" // Optional.[String].Default: "".
                  customStyleInput={{}} // Optional.[Object].Default: {}.
                  customStyleWrapper={{}} // Optional.[Object].Default: {}.
                  customStyleContainer={{}} // Optional.[Object].Default: {}.
                  onChange={(refContactName, e) => {
                    this.setState({ refContactName });
                    console.log(e);
                  }} // Required.[Func].Default: () => {}. Will return the value.
                  onBlur={e => {
                    console.log(e);
                  }} // Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.

                  validationOption={{
                    firstName: "refContactName", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                    check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                    required: false // Optional.[Bool].Default: true. To determin if it is a required field.

                  }}
                />
              </Col>
              <Col lg="6" md="6" sm="6">
                <label>Contact Number 1</label>
                <Textbox
                  attributesInput={{
                    id: "refcontactNumber1",
                    placeholder: "Referer Contact Number",
                    type: "text"
                  }}
                  value={refcontactNumber1} //Optional.[String].Default: "".

                  onChange={(refcontactNumber1, e) => {
                    this.setState({ refcontactNumber1 });
                    console.log(e);
                  }} //Required.[Func].Default: () => {}. Will return the value.
                  onBlur={e => { }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                  validationOption={{
                    name: "refcontactNumber1", //Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                    check: true, //Optional.[Bool].Default: true. To determin if you need to validate.
                    required: true, //Optional.[Bool].Default: true. To determin if it is a required field.
                    customFunc: refcontactNumber1 => {
                      if (validator.isMobilePhone(refcontactNumber1, "en-US")) {
                        return true;
                      } else {
                        return "is not a valid phone number";
                      }
                    }
                  }}
                />
              </Col>
            </Row>
            <div style={{ height: "10px" }} />
            <div
              className={`my-button my-button__red save-button`}
              onClick={this.validateForm}>
              Proceed to view Order Summary!
            </div>
            <input type="submit" style={{ display: "none" }} />
          </form>

        </div>



        <div className={`productTable table-responsive ${(this.state.isUserFormFilled && this.state.currentActiveTab == 'buy') ? 'showFormCls' : 'hiddenFormCls'}`}>





          <table id="table" className="table">
            <thead>
              <tr>
              <th>Product Name</th>
           <th>Price</th>
           <th>Quantity</th>
           <th>Amount</th>

              </tr>
            </thead>
            <tbody>
              {
                this.state.productSummaryList.map((ele, i) => (
                  <tr key={i}>
                    <td>
                    {ele.productName} 
                    </td>
                    <td style={{ "vertical-align": "middle" }}>{ele.price}</td>
                    <td>
                    {ele.quantity} 
                    </td>
                    <td>{ele.totalAmount}</td>
                  </tr>
                ))
              }
            </tbody>
            <tfoot>
              <tr>
                <th id="total" colspan="3">Total Amount:</th>
           <td>{this.state.netAmount}</td>
              </tr>
            </tfoot>
          </table>
          <hr />
          <Row className="txtCenterCart">
            <Col lg="6" md="6" sm="6">
              <button type="button" className="btn btn-success cartButtons" onClick={this.clickContinueShoppingBtn}>Continue Shopping <i className="fa fa-cart-plus continueShopping"></i></button>
            </Col>
            <Col lg="6" md="6" sm="6">
              <button type="button" className="btn btn-success cartButtons" onClick={this.proceedCheckOutButtonClick}>Proceed to checkout<i className="fa fa-shopping-cart continueShopping"></i><i className="fa fa-check-circle tickIcon"></i></button>
            </Col>
          </Row>
        </div>
        <div className={`productTable table-responsive ${(this.state.isUserFormFilled && this.state.currentActiveTab == 'rent') ? 'showFormCls' : 'hiddenFormCls'}`}>
          <table id="table2" className="table">
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Price / Hr</th>
                <th>From Date</th>
                <th>To Date</th>
                <th>Total Days</th>
                <th>Quantity</th>
               <th>Amount</th> 
              </tr>
            </thead>
            <tbody>
              {
                this.state.productSummaryList.map((ele, i) => (
                  <tr key={i}>
                    <td>{ele.productName}</td>
                    <td>{ele.price}</td>
                    {/* <td>{moment(ele.fromDateTime).format("MMMM Do YYYY, h:mm a")}</td>
                    <td>{moment(ele.toDateTime).format("MMMM Do YYYY, h:mm a")}</td> */}
                    <td>{moment(ele.fromDateTime).format("MMMM DD YYYY")}</td>
                    <td>{moment(ele.toDateTime).format("MMMM DD YYYY")}</td>
                    <td>{ele.totalHoursForRent}</td>
                    <td>{ele.quantity}</td>
                    <td>{ele.totalAmount}</td>
                  </tr>
                ))
              }
            </tbody>
            <tfoot>
              <tr>
                 <th id="total" colspan="6">Total Amount:</th>
                    <td>{this.state.netAmount}</td> 
              </tr>
            </tfoot>
          </table>
          <hr />
          <Row className="txtCenterCart">
            <Col lg="6" md="6" sm="6">
              <button type="button" className="btn btn-success cartButtons" onClick={this.clickContinueShoppingBtn}>Continue Shopping <i className="fa fa-cart-plus continueShopping"></i></button>
            </Col>
            <Col lg="6" md="6" sm="6">
              <button type="button" className="btn btn-success cartButtons" onClick={this.proceedCheckOutButtonClick}>Proceed to checkout<i className="fa fa-shopping-cart continueShopping"></i><i className="fa fa-check-circle tickIcon"></i></button>
            </Col>
          </Row>

        </div>
      </div>
    );
  }
}

const mapDispatchToState = (dispatch) => {
  return bindActionCreators(
    {
      storeFormValues: submitFormBuyProductStoreValue,
      submitPurchaseOrder: submitPurchaseOrder,
      updateBuyProductArrayLocalStore: updateBuyProductArrayLocalStore,
      updateRentProductArrayLocalStore: updateRentProductArrayLocalStore
    },
    dispatch
  );
};
function mapStateToProps(state) {
  return {
    resultProductArrayBuy: state.updatedbuyproductArrayLocalStore,
    resultProductArrayRent: state.updatedrentalproductArrayLocalStore,
    getUserFormValues: state.userFormDataValuesFromStore
    //reusltFormSubmitResponse:state.buyProductFormSubmitResponse
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToState
)(ValidationForm)