import React, { Component,useState,useEffect } from 'react';
import './BuyProduct.css';
import { Container, Row, Col, Tab, Nav,Image,Button } from "react-bootstrap";
import InputSpinner from 'react-bootstrap-input-spinner';
import { BadgeMessageService } from '../../badge_services/badgeMessageService'
import { VirtualTimeScheduler } from 'rxjs';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateBuyProductArrayLocalStore,updateRentProductArrayLocalStore,getProductList } from '../../action/app.action';
import  { Redirect } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';

import Pagination from "./Pagination";
const BuyProduct = (props)=> {
    const [key, setKey] = useState('first');

    //Horizontal tab
    const [currentTab, handleClick] = useState(0);
    
    const imageHttpUrl="https://admin.wegocareplus.com/";
    
   /* const finalImages =  importAll(require.context("../../assets/", false, /\.(png|jpe?g|svg)$/));*/
     //Select drop down Buy count
     const [data, setData] = useState(null);

  /* function importAll(r) {
      let images = {};
      r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
      return images;
  };*/

  

 
      const [buyProductArray,setBuyProductArray]=useState([]);
      const [buyProductArrayPaginatedData,setBuyProductArrayPaginatedData]=useState([]);
      const [rentProductArrayPaginatedData,setRentProductArrayPaginatedData]=useState([]);
      const [rentProductArray,setRentProductArray]=useState([]);
      const [buyProductDataArrayCopy, setBuyProductDataArrayCopy]=useState([]);
      const [rentProductDataArrayCopy, setRentProductDataArrayCopy]=useState([]);
      const [productBuyCountArray, setProductBuyCountArray]=useState([]);

      const [buyProductActivePage, setBuyProductActivePage]=useState(1);
      const [rentProductActivePage, setrentProductActivePage]=useState(1);

      const [totalBuyProductCount,setTotalBuyProductCount]=useState(0);
      const [totalRentProductCount,setTotalRentProductCount]=useState(0);

      const [redirect, SetRedirect] = useState(false)

      useEffect(() => {
        localStorage.setItem('tabName','buy');
             let tempBuyArray=[];
             let tempRentArray=[];
            
            if (props.productListResponse != undefined){
              props.productListResponse.forEach(ele=>{
                if(!ele.isRent){
                  tempBuyArray.push(ele);
                }
                else{
                  ele.fromDateTime=new Date();
                  ele.toDateTime=new Date();
                  ele.totalHoursForRent=0;
                  tempRentArray.push(ele);
                }
                
              })
             }
             setTotalBuyProductCount(tempBuyArray.length);
             setTotalRentProductCount(tempRentArray.length);
            setBuyProductArray(tempBuyArray);
            setRentProductArray(tempRentArray);
            setBuyProductDataArrayCopy(tempBuyArray);
            setRentProductDataArrayCopy(tempRentArray);

            setInitialPagination(tempBuyArray,tempRentArray);
            window.scrollTo(0, 0);
            
      },[]);

     const setInitialPagination=(buyArray,rentArray)=>{
      setBuyProductArrayPaginatedData(buyArray.slice((1 - 1) * 3, 1 * 3));
      setRentProductArrayPaginatedData(rentArray.slice((1 - 1) * 3, 1 * 3));
     }
     


      const handlePageChangeBuyProduct=(pageNumber) =>{
     //   console.log("buyProduct called");
       // console.log(pageNumber);
       setBuyProductActivePage(pageNumber);
       setBuyProductArrayPaginatedData(buyProductArray.slice((pageNumber - 1) * 3, pageNumber * 3));      //  //setBuyProductActivePage(pageNumber);
       // array.slice((pageNumber - 1) * 3, pageNumber * 3);
      }

      const handlePageChangeRentProduct=(pageNumber) =>{
      console.log(pageNumber);
        setrentProductActivePage(pageNumber);
        setRentProductArrayPaginatedData(rentProductArray.slice((pageNumber - 1) * 3, pageNumber * 3));      //  //setBuyProductActivePage(pageNumber);

      }

     
    const updateBuyItemCount=(count,id)=>{

      //push product details to new array with productBuyStatus attribute true/false
      let checkItemCountAlreadyAddedStatus=0;

         if((productBuyCountArray.length==0) && (count>0)){   //First time product added to array
              setProductBuyCountArray([{"id":id,"quantity":count}]);
          }
          if(productBuyCountArray.length>0){
              productBuyCountArray.forEach(function(value){
                if(value.id==id){
                      checkItemCountAlreadyAddedStatus=1; //Item already added
                }
              });
          }
            if((checkItemCountAlreadyAddedStatus == 0) && (count>0)){   //New Item
                productBuyCountArray.push({"id":id,"quantity":count});
            }
            else if((checkItemCountAlreadyAddedStatus == 1) && (count>0)){ //Item already added compare and push new count
              productBuyCountArray.forEach(function(value){
                          if(value.id==id){
                            if(count>0){
                                value.quantity=count;
                              }
                          }
                    });
            }
            
            else if((checkItemCountAlreadyAddedStatus == 1) && (count==0)){
              if(localStorage.getItem('tabName') == 'buy'){
              buyProductDataArrayCopy.forEach(elem=>{
                if(elem.productId ==id){
                  elem.quantity=count;     //STORE THE ARRAY VALUE TO ZERO 
                  elem.productBuyingStatus=false;
                  elem.totalAmount=0;
                }
              });
            }
            else{
              rentProductDataArrayCopy.forEach(elem=>{
                if(elem.productId ==id){
                  elem.quantity=count;     //STORE THE ARRAY VALUE TO ZERO 
                  elem.productBuyingStatus=false;
                  elem.totalAmount=0;
                }
              });
            }
              
          
              const index = productBuyCountArray.findIndex(prod => prod.id === id); //use id instead of index
           if (index > -1) { //make sure you found it
                  productBuyCountArray.splice(index, 1);
                }            
  
  }
    }


    const addToCart=(productId)=>{
     if(localStorage.getItem('tabName') == 'buy'){
            let productCount=0;
              //Compare valeus productBuyCountArray vs productDataCopy Array and push quantity based on ID
              buyProductDataArrayCopy.forEach(function(element){
                productBuyCountArray.forEach(function(countingArray){
                      if(element.productId == countingArray.id){
                                if(countingArray.quantity >0)  
                                element.quantity=countingArray.quantity;
                                element.productBuyingStatus=true;
                                element.totalAmount=(element.quantity) * (element.price)
                      }
                });
                if(element.productBuyingStatus == true){
                        productCount++;
                }
              });
              props.updateBuyProductArrayLocalStore(buyProductDataArrayCopy);
              BadgeMessageService.sendMessage(productCount);
    }
    else{
      let productCount=0;
      let tempTotalHours=0;

              //Compare valeus productBuyCountArray vs productDataCopy Array and push quantity based on ID
              rentProductDataArrayCopy.forEach(function(element){
                productBuyCountArray.forEach(function(countingArray){
                      if(element.productId == countingArray.id){
                                if(countingArray.quantity >0)  
                                element.quantity=countingArray.quantity;
                                element.productBuyingStatus=true;

                                //Calculate based on Number of hours and price per hour
                                //   tempTotalHours= Math.abs(new Date(element.toDateTime) - new Date(element.fromDateTime))/36e5;
                                //   element.totalHoursForRent=tempTotalHours.toFixed(0);
                                // element.totalAmount=Number((element.quantity) * (element.price) * (tempTotalHours)).toFixed(0);

                                //Calculate based on Number of Days Required
                                let Difference_In_Time=Math.abs(new Date(element.toDateTime) - new Date(element.fromDateTime));
                                let Difference_In_Days=Difference_In_Time / (1000 * 3600 * 24);
                                element.totalHoursForRent=Difference_In_Days.toFixed(0);
                                element.totalAmount=Number((element.quantity) * (element.price) * Difference_In_Days.toFixed(0));

                      }
                });
                if(element.productBuyingStatus == true){
                        productCount++;
                }
              });
              props.updateRentProductArrayLocalStore(rentProductDataArrayCopy);
              BadgeMessageService.sendMessage(productCount);

    }
    }

    const modeofPurhcase=(keyValue)=>{
     
     // console.log(keyValue);
      localStorage.setItem('tabName', keyValue);
      let badgeCount=0;
      if(keyValue == "buy"){
        if(props.updatedbuyproductArrayLocalStore !=undefined){
          props.updatedbuyproductArrayLocalStore.forEach(ele=>{
                   if(ele.productBuyingStatus){
                     badgeCount++;
                   }
          });
        }
        BadgeMessageService.sendMessage(badgeCount);
      }
      else{
        if(props.updatedRentProductArrayLocalStore !=undefined){
          props.updatedRentProductArrayLocalStore.forEach(ele=>{
                   if(ele.productBuyingStatus){
                     badgeCount++;
                   }
          });
        }
        BadgeMessageService.sendMessage(badgeCount);

      }
    
  
    }

    const setDateValue=(productId,dateValue,pickerType)=>{

     let tempCopyArray=[...rentProductDataArrayCopy];

     tempCopyArray.forEach(ele=>{
        if(productId == ele.productId){
            if(pickerType == 'fromDate'){
              ele.fromDateTime=dateValue;
            }
            else{
               ele.toDateTime=dateValue;      
            }
        }
      });
     
      setRentProductDataArrayCopy(tempCopyArray);
     

    }
  
   
  
    
    return (
       redirect ? <Redirect push to="/contact" /> : <Container  style={{ width: "auto" }}>
         
        <Tab.Container activeKey={key} onSelect={key => setKey(key)}>
          <Row>
            <Col className="colTxt">
            <div>
                <Nav.Link
                eventKey="first" >
                <div className={"txtCenter",key === 'first' ? "txtUnderLine" : "rmvUnderLine"} onClick={()=>modeofPurhcase("buy")}>Buy</div>
                 </Nav.Link>
                
            </div>
            </Col>
            <Col className="colTxt">
              <Nav.Link eventKey="second" >
              <div  className={"txtCenter",key === 'second' ? "txtUnderLine" : "rmvUnderLine"} onClick={()=>modeofPurhcase("rent")}>Rent</div>
              </Nav.Link>
            </Col>
          </Row>

          <Tab.Content>
            <Tab.Pane eventKey="first">
              <br/>
              <div className="text-center blink">Are you looking for any New medical equipment?</div>
              <div className="text-center txtColor">Please <span className="anchorTag" onClick={()=>{ SetRedirect(true)}}>CLICK HERE</span> to drop a message to us!!! </div>
                 <div className="responsiveGrid">
                    {
              buyProductArrayPaginatedData.map((tile,i)=>
                   <div key={tile.productId} className="tileData">
                    <div className="tile">
                      <div className="image">

                      <img className="imgSize" src ={imageHttpUrl+tile.imageUrl} />

                      </div>
                      <div className="price">
                        <div>
                             {tile.productName}
                        </div>
                        <hr/>
                        <div className="row">
                        <Col lg="6" md="6" sm="6" xs="6">
                                Price : 
                             </Col>
                            
                           
                            <Col lg="6" md="6" sm="6" xs="6" className="">
                             <span class="WebRupee">&#x20B9;</span> {tile.price} 
                            </Col>
                        </div>
                        <hr/>
                        <div className="row">
                        <Col lg="6" md="6" sm="6" xs="6"  >
                                Qty
                             </Col>
                             <Col lg="6" md="6" sm="6" xs="6" className="inputSpinner">
                            <InputSpinner
                                    type={'real'}
                                    precision={2}
                                    max={100}
                                    min={0}
                                    step={1}
                                    value={tile.quantity}
                                    onChange={num=>updateBuyItemCount(num,tile.productId)}
                                    variant={'dark'}
                                    size="sm"
                                   
                              />

                            </Col>
                         
                        </div>
                      
                     
                      <hr/>
                     
                         
                      </div>
                      <div className="text-center">
                        <Button className="buyBtn"  value={tile.name} onClick={()=>addToCart(tile.productId)}>Add to cart</Button>
                      </div>

                    </div>
                  </div>

              )
            }
        </div>
        <Row>
                <Col lg="12" md="12" sm="12" >
                
                <Pagination
            currentPage={buyProductActivePage}
            totalPages={Math.ceil(totalBuyProductCount/3)}
            changeCurrentPage={handlePageChangeBuyProduct}
            theme="square-fill"
          />

                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
            <br/>
              <div className="text-center blink">Are you looking for any New medical equipment?</div>
              <div className="text-center txtColor">Please <a className="anchorTag" href="/contact">CLICK HERE</a> to drop a message to us!!! </div>
            <div className="responsiveGrid">
                    {
              rentProductArrayPaginatedData.map((tile,i)=>
                   <div key={tile.productId} className="tileData">
                    <div className="tile">
                      <div className="image">
                      <img className="imgSize" src ={imageHttpUrl+tile.imageUrl} />
                      </div>
                      <div className="price">
                        <span>
                             {tile.productName}
                        </span>
                        <hr/>
                        <div className="row">
                             <Col lg="5" md="5" sm="5" xs="5">
                                 Price :
                             </Col>
                             <Col lg="7" md="7" sm="7" xs="7" style={{'text-align':'center'}} >
                             <span class="WebRupee">&#x20B9;</span> {tile.price}/Day
                             </Col>
                        </div>
                        <hr/>
                        <div className="row">
                            <Col lg="6" md="6" sm="6" xs="6" className="">
                            Qty
                            </Col>
                            <Col lg="6" md="6" sm="6" xs="6" className="inputSpinner">
                            <InputSpinner
                                    type={'real'}
                                    precision={2}
                                    max={100}
                                    min={0}
                                    step={1}
                                    value={tile.quantity}
                                    onChange={num=>updateBuyItemCount(num,tile.productId)}
                                    variant={'dark'}
                                    size="sm"
                                   
                              />

                            </Col>
                        </div>
                      <hr/>
                      <div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          renderInput={(props) => <TextField {...props} helperText="" />}
                          label="Select From Date and Time"
                          value={tile.fromDateTime}
                          disablePast
                          onChange={(newValue) => {
                            setDateValue(tile.productId,newValue,"fromDate");
                          }}
                        />
                       </LocalizationProvider>
                       </div>
                       <div className="toDateTime">
                            <LocalizationProvider dateAdapter={AdapterDateFns}  className="toDateTime">
                              <DatePicker
                                renderInput={(props) => <TextField {...props} helperText=""  />}
                                label="Select To Date and Time"
                                disablePast
                                value={tile.toDateTime}
                                onChange={(newValue) => {
                                  setDateValue(tile.productId,newValue,"toDate");
                                }}
                              />
                            </LocalizationProvider>
                       </div>
                      
                      </div>
                      <div className="text-center">
                        <Button className="buyBtn"  value={tile.name} onClick={()=>addToCart(tile.productId)}>Add to cart</Button>
                      </div>

                    </div>
                  </div>
              )
            }
        </div>
        <Row>
                <Col lg="12" md="12" sm="12">
                 
                <Pagination
            currentPage={rentProductActivePage}
            totalPages={Math.ceil(totalRentProductCount/3)}
            changeCurrentPage={handlePageChangeRentProduct}
            theme="square-fill"
          />

                </Col>


                
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>
    )
}

const mapStateToProps = (state) => {
   return {
    updatedbuyproductArrayLocalStore: state.updatedbuyproductArrayLocalStore,
    updatedRentProductArrayLocalStore:state.updatedrentalproductArrayLocalStore,
    productListResponse:state.productList
   };
};

const mapDispatchToState = (dispatch) => {
   return bindActionCreators(
      {
        updateBuyProductArrayLocalStore: updateBuyProductArrayLocalStore,
        updateRentProductArrayLocalStore:updateRentProductArrayLocalStore
      //  getProductList:getProductList
     },
     dispatch
   );
};

export default connect(
  mapStateToProps,
  mapDispatchToState
)(BuyProduct);
