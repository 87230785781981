import { PAGE_CONTENT_LIST,AMBULANCE_LIST,BLOODBANK_LIST,PRODUCT_LIST,UPDATEDBUYPRODUCT_ARRAY_LOCALSTORE,UPDATEDRENTALPRODUCT_ARRAY_LOCALSTORE,USERFORMDATA_IN_STORE, NEWSLETTER_RESPONSE,CONTACTUS_RESPONSE,PURCHASEORDER_RESPONSE,SERVICE_CONTENT_RESPONSE } from '../action/actionType'

const AppState = {
 
}


export const appReducer = (state = AppState, action) => {
  console.log(action);
  switch (action.type) {
    // case GET_CUSTOMERS:
    //   state.customerList = [{...action.payload}];
    //   return {...state};
    //   case GET_PRODUCT:
    //     state.allProducts = action.payload;
    //   return {...state};
    //   case IS_LOGIN:
    //     state.userDetails = {...action.payload};
    //     return {...state};
    // case PRODUCT_CATEGORIES:
    //   return {...state};
    case PAGE_CONTENT_LIST:
        state.pageContentList=action.payload;
        return {...state};
    case AMBULANCE_LIST:
        state.ambulanceList=action.payload;
        return {...state};
    case BLOODBANK_LIST:
        state.bloodBankList=action.payload;
        return {...state}; 
    case PRODUCT_LIST:
        state.productList=action.payload;
        return {...state};   
    case UPDATEDBUYPRODUCT_ARRAY_LOCALSTORE:
        state.updatedbuyproductArrayLocalStore=action.payload;
        return {...state};    
    case UPDATEDRENTALPRODUCT_ARRAY_LOCALSTORE:
        state.updatedrentalproductArrayLocalStore=action.payload;
        return {...state}    
    case USERFORMDATA_IN_STORE:
        state.userFormDataValuesFromStore=action.payload;
        return {...state};
        
    case NEWSLETTER_RESPONSE:
        state.newsLetterResponse=action.payload;
        return {...state};    

    case CONTACTUS_RESPONSE:
        state.countactusFormResponse=action.payload;
        return {...state}; 
            
            
    case PURCHASEORDER_RESPONSE:
        state.purchaseOrderResponse=action.payload;
        return {...state};  
        
    case SERVICE_CONTENT_RESPONSE:
         state.serviceContentResponse=action.payload;
         return {...state};    
    default:
      return {...state};
  }
}